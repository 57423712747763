import React, { useEffect, useMemo } from "react";
import { venues } from "../../modules/auth";
import { refreshUserVenues } from "../../modules/auth-thunks";
import { connect } from "react-redux";
import { currencyFormatter } from "../../utils";
import { Link, useRouteMatch } from "react-router-dom";
import * as ROUTES from "../../Router/venue_routes";
import * as VENUE_ROUTES from "../../Router/venue_routes";

const UPGRADE_PRICE = currencyFormatter("GBP").format(75);

function VenueUpgradeBanner({ venues, refreshUserVenues, currentVenue }) {
  const match = useRouteMatch(VENUE_ROUTES.FRONTEND.MANAGE_VENUE_LICENCE.INDEX);
  const matchAdminWelcome = useRouteMatch("/admin/welcome");
  const matchPublicHome = useRouteMatch("/");
  const matchEditVenue = useRouteMatch(VENUE_ROUTES.FRONTEND.VENUE.EDIT);
  const matchViewVenue = useRouteMatch(VENUE_ROUTES.FRONTEND.VENUE.PUBLIC_VIEW);
  const matchVenuesDashboard = useRouteMatch(VENUE_ROUTES.FRONTEND.VENUE.DASHBOARD);

  useEffect(() => {
    refreshUserVenues(true);
  }, [refreshUserVenues]);

  const venuesToUpgrade = useMemo(() => {
    const venuesSet = new Set();

    if (currentVenue && currentVenue.temp_permission === "free") {
      if (venues.find(venue => currentVenue.id === venue.id)) {
        venuesSet.add(currentVenue);
      }
    } else if (matchEditVenue) {
      let editVenue = venues.find(venue => venue.slug === matchEditVenue.params.venue);
      if (editVenue && editVenue.temp_permission === "free") {
        venuesSet.add(editVenue);
      }
    } else if (!currentVenue) {
      venues.forEach((venue) => {
        if (venue.temp_permission === "free") {
          venuesSet.add(venue);
        }
      });
    }

    return Array.from(venuesSet);
  }, [venues, currentVenue, matchEditVenue]);

  const upgradeText = useMemo(() => {
    if (venuesToUpgrade.length === 1) {
      const { name, slug } = venuesToUpgrade[0];
      return (
        <>
          Upgrade <Link className={"text-white underline"}
                        to={`${ROUTES.FRONTEND.MANAGE_VENUE_LICENCE.INDEX}?venue=${slug}`}>{name}</Link> to be a
          licensed venue for only {UPGRADE_PRICE}+VAT per month to gain access to 20+ features - <Link
          className={"text-white underline"}
          to={`${ROUTES.FRONTEND.MANAGE_VENUE_LICENCE.LICENSED_VENUE_PROMO_PAGE}?venue=${slug}`}>read more</Link>.
        </>
      );
    } else {
      const linkedVenueNames = venuesToUpgrade.splice(0, 3).map((venue, index) => (
        <span key={venue.name}>
          <Link className={"text-white underline"}
                to={`${ROUTES.FRONTEND.MANAGE_VENUE_LICENCE.INDEX}?venue=${venue.slug}`}>{venue.name}</Link>
          {index < venuesToUpgrade.length - 1 ? ", " : ""}
        </span>
      ));

      return (
        <>
          Upgrade {linkedVenueNames} {venuesToUpgrade.length > 3 ? ` + ${venuesToUpgrade.length - linkedVenueNames.length} more ` : null} to
          be licensed venues for only {UPGRADE_PRICE}+VAT - <Link
          className={"text-white underline"}
          to={ROUTES.FRONTEND.MANAGE_VENUE_LICENCE.LICENSED_VENUE_PROMO_PAGE}>Read more</Link>.
        </>
      );
    }
  }, [venuesToUpgrade]);

  if (match) {
    return null;
  }

  if (!matchAdminWelcome && !matchEditVenue && !matchVenuesDashboard && !matchViewVenue && !matchPublicHome) {
    return null;
  }

  if (venuesToUpgrade.length === 0) {
    return null;
  }

  return (
    <div className={`bg-primary px-3 py-6 block font-bold text-white my-5`}>
      <h3>Upgrade your venue today</h3>
      {upgradeText}
    </div>
  );
}

const mapStateToProps = (state) => ({
  venues: venues(state),
});

const mapDispatchToProps = { refreshUserVenues };

export default connect(mapStateToProps, mapDispatchToProps)(VenueUpgradeBanner);
